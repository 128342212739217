.App {
  text-align: center;
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 20px 10px 20px 10px;
}

.header {
  color: white;
  font-size:50px;
  font-family: BrokenConsoleBold
}

.pfpContainer {
  width: 350px;
  height: 350px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.buttonContainer {
  display: flex;
  flex-direction: row;
}

.submitButton {
  width: 200px;
  height: 48px;
  font-size: 24px;
  margin: 10px 0px 10px 0px;
  cursor: pointer;
  font-family: BrokenConsoleBold;
  vertical-align: center;
  align-items: center;
  text-align: center;
  border: 1px solid black;
  padding-top: 2px;
  background-color: white;
}

.textInputContainer {
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
  align-items: center;
}

.textInput {
  margin-left: 10px;
  background-color: transparent;
  border-radius: 0px;
  border: none;
  font-size: 20px;
  height: 20px;
  width: 100px;
  font-weight: bold;
  color: white;
  vertical-align: text-bottom;
  margin-top: 2px;
  border-bottom: 2px solid white;
}

.h2 {
  font-size: 36px;
  color: white;
  font-weight: bold;
  font-family: BrokenConsoleBold
}

.h3 {
  font-size: 20px;
  color: white;
  font-weight: bold;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  outline: none;
  margin-left: 20px;
}
.switch input {
  position: absolute;
  top: -99999px;
  left: -99999px;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 34px;
}
.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2196F3;
}
input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}
input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

@media only screen and (max-width: 600px) {
  .header {
    margin-top: 40px;
  }
  .textInput {
    margin-top: 0px;
  }
  .buttonContainer {
    flex-direction: column;
  }
  .submitButton {
    margin-right: 0px
  }
}
